// src/pages/Login.js
import React from 'react';
import { useEffect, useState, useContext, useRef } from 'react';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useCheckout } from '../states/CheckoutContext';
import { useNavigate } from 'react-router-dom'; // Importe useHistory para lidar com o redirecionamento
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { baseUrl } from '../config'; //Importa o link do server
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from 'uuid';

//Importação das imagens
import logo from './../images/logo.png';
import selo_seguranca from './../images/selo_seguranca.svg';
import alterar from './../images/alterar.svg';
import cartao from './../images/cartao.svg';
import pix from './../images/pix.svg';
import produto from './../images/produto.png';
import certo from './../images/contem.svg';


//Início do Componente Checkout
function Checkout() {

  // Acessar o contexto
  const checkoutContext = useCheckout();

  // Extrair os estados do contexto
  const { numParcelas, setNumParcelas, quantidadeProduto, setQuantidadeProduto, valor, setValor, valorFinal, setvalorFinal, cupomId, setCupomId } = checkoutContext;

  //Refs para rolagem conforme o clique do usuário
  const etapa1Ref = useRef(null);
  const etapa2Ref = useRef(null);
  const etapa3Ref = useRef(null);

  //Definição primária para o funcionamento
  const [showPopup, setShowPopup] = useState(false);
  const [showError, setShowError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Obtenha o objeto history
  const { formData, setFormData } = useCheckout();
  const [errors, setErrors] = useState({});
  const [isAllValid, setIsAllValid] = useState(false);
  const [isAddressReadOnly, setIsAddressReadOnly] = useState(true);
  const [isNeighborhoodReadOnly, setIsNeighborhoodReadOnly] = useState(true);
  const [isNumberReadOnly, setIsNumberReadOnly] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [cepStatus, setCepStatus] = useState("");
  const [etapa2Enviada, setEtapa2Enviada] = useState(false);
  const [buttonFinal, setbuttonFinal] = useState("false"); //Mapeando o clique final do usuário

  // Adicione um estado para controlar se os campos são editáveis
  const [isAddressEditable, setIsAddressEditable] = useState(true);

  //Parte relacionada a aplicação do cupom
  const [cupom, setCupom] = useState('');
  const [valorTotalComDesconto, setValorTotalComDesconto] = useState(quantidadeProduto * valor);
  const [porcentagemDesconto, setporcentagemDesconto] = useState('');
  const [msgCupom, setmsgCupom] = useState("");
  const [cupomAutomatico, setCupomAutomatico] = useState('');

  // Função para calcular o valor das parcelas com base na quantidade de produtos e no desconto
  const calcularValorParcelas = (quantidade, desconto) => {
    const valorSemDesconto = quantidade * valor;
    const valorComDesconto = valorSemDesconto - desconto;
    return valorComDesconto;
  };
  

  //Formatação do valor em R$
  function formatCurrency(value) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }
  // Função para remover vírgulas e pontos de uma string e retornar um valor numérico
  const removeCommasAndDots = (str) => {
    if (typeof str !== 'string') {
      return str;
    }

    const stringWithoutCommasAndDots = str.replace(/[,.]/g, '');
    return parseFloat(stringWithoutCommasAndDots);
  };

  // UseEffect para atualizar o valor das parcelas sempre que a quantidade ou o cupom for alterado
  useEffect(() => {
    // Calcule o valor das parcelas com base na quantidade e no desconto atual
    const valorParcelas = calcularValorParcelas(quantidadeProduto, (porcentagemDesconto / 100) * (quantidadeProduto * valor));

    // Atualize o estado do valorTotalComDesconto com o novo valor calculado
    setValorTotalComDesconto(valorParcelas);
  }, [quantidadeProduto, porcentagemDesconto]);

  // Função responsável pelo ajuste de quantidade
  const incrementQuantidadeProduto = () => {
    if (quantidadeProduto < 10) {
      setQuantidadeProduto(quantidadeProduto + 1);
    }
  };

  const decrementQuantidadeProduto = () => {
    if (quantidadeProduto > 1) {
      setQuantidadeProduto(quantidadeProduto - 1);
    }
  };

  //Função responsável pela validação dos campos
  const validateField = (name, value) => {
    let error = "";

    const isValidCPF = (cpf) => {
      cpf = cpf.replace(/[^\d]+/g, '');
      if (cpf === '') return false;
      if (cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999")
        return false;
      let add = 0;
      for (let i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
      let rev = 11 - (add % 11);
      if (rev === 10 || rev === 11)
        rev = 0;
      if (rev !== parseInt(cpf.charAt(9)))
        return false;
      add = 0;
      for (let i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11)
        rev = 0;
      if (rev !== parseInt(cpf.charAt(10)))
        return false;
      return true;
    };

    switch (name) {
      case 'nome':
        if (!value) error = "Nome é obrigatório.";
        break;
      case 'email':
        if (!value) {
          error = "E-mail é obrigatório.";
        } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) {
          error = "Formato de e-mail inválido.";
        }
        break;
      case 'email_2':
        if (value !== formData.email) error = "E-mails não coincidem.";
        break;
      case 'cpf':
        if (!isValidCPF(value)) error = "CPF inválido.";
        break;
      case 'whatsapp':
        if (!value || value.includes('_')) error = "Telefone inválido.";
        break;
      case 'cep':
      case 'endereco':
      case 'bairro':
      case 'numero':
      case 'estado':
      case 'cidade':
        if (!value) error = `${name.charAt(0).toUpperCase() + name.slice(1)} é obrigatório.`;
        break;
      case 'numero_cartao':
        if (!value || value.includes('_')) error = "Número do cartão inválido.";
        break;
      case 'nome_cartao':
        if (!value) error = "Nome é obrigatório.";
        break;
      case 'mes_expiracao':
        const month = parseInt(value);
        if (!value || value.includes('_') || month < 1 || month > 12) error = "Mês de expiração inválido.";
        break;
      case 'ano_expiracao':
        if (!value || value.includes('_')) error = "Data de expiração inválida.";
        break;
      case 'codigo_cartao':
        if (!value) error = "Código de segurança inválido.";
        break;
      default:
        break;
    }

    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
  };


  //Função que limpa os campos de Endereço
  const clearAddressFields = () => {
    setFormData(prevState => ({
      ...prevState,
      endereco: "",
      bairro: "",
      cidade: "",
      estado: "",
      numero: ""
    }));
    setIsAddressReadOnly(false);
    setIsNeighborhoodReadOnly(false);
    setIsNumberReadOnly(false);
  };

  //Função que faz a busca do CEP
  const fetchCEPData = async (value) => {
    try {
      setCepStatus("Buscando CEP...");
      const response = await fetch(`https://viacep.com.br/ws/${value}/json/`);

      const data = await response.json();

      if (data.erro) {
        setCepStatus("CEP não encontrado");
        clearAddressFields();
        return;
      } else {
        setCepStatus("");
        // Define isAddressEditable como falso se os dados do CEP estiverem disponíveis
        setIsAddressEditable(false);
      }

      setFormData(prevState => ({
        ...prevState,
        endereco: data.logradouro || "",
        bairro: data.bairro || "",
        cidade: data.localidade || "",
        estado: data.uf || ""
      }));

    } catch (error) {
      console.error("Erro ao buscar CEP:", error);
      setCepStatus("Erro ao buscar CEP");
      clearAddressFields();
    }
  };

  // Função que envia os dados da etapa 2 para o backend
  const sendToBackendStep2 = async () => {
    try {
      // Envie os dados para o backend
      await sendToBackend(2);
      setEtapa2Enviada(true); // Marque a etapa 2 como enviada com sucesso
    } catch (error) {
      console.error("Erro ao enviar dados para o backend:", error);
      // Lide com erros aqui, se necessário
    }
  };

  //Função responsável pela validação e ajustes das mudança dos campos 
  const handleInputChange = async (e) => {
    let { name, value } = e.target;

    if (name === "cep") {
      // Lógica de formatação do CEP
      value = value.replace(/\D/g, ""); // Remova todos os caracteres não numéricos

      // Aplica a máscara 00000-000
      if (value.length <= 5) {
        value = value.replace(/(\d{0,5})/, "$1");
      } else {
        value = value.replace(/(\d{0,5})(\d{0,3})/, "$1-$2");
      }

      clearAddressFields(); // Limpa os campos sempre que o CEP for alterado

      if (value.length < 9) { // Note que agora verificamos se a string tem menos de 9 caracteres (pois incluímos o '-')
        setCepStatus("Buscando CEP..."); // Retorna a mensagem para o usuário
        //Enviar a etapa 2, pois o usuário digitou o CEP
        // Enviar, mas apenas se ainda não tiver sido enviada
        if (!etapa2Enviada) {
          sendToBackendStep2(); // Execute a função de envio em segundo plano
        }
      } else {
        setCepStatus("");

        const sanitizedCEP = value.replace(/-/g, ''); // Remove o '-' do CEP
        fetchCEPData(sanitizedCEP);
      }
    }

    setFormData(prevState => ({ ...prevState, [name]: value }));
    validateField(name, value);
  };

  const isAllValidEtapa01 = () => {
    const requiredFields = ["nome", "email", "email_2", "cpf", "whatsapp"];
    return requiredFields.every(field => !errors[field] && formData[field]);
  };

  const isAllValidEtapa02 = () => {
    const requiredFieldsEtapa02 = ["cep", "endereco", "bairro", "numero", "estado", "cidade"];
    return requiredFieldsEtapa02.every(field => formData[field]);
  };

  useEffect(() => {
    const areAllFieldsValid = !Object.values(errors).some(error => error) &&
      !Object.values(formData).some(v => !v);
    setIsAllValid(areAllFieldsValid);
  }, [errors, formData]);

  //Função relacionada ao clique do usuário para Avançar etapas
  const handleNextStep = (step) => {
    if (step === 1 && isAllValidEtapa01()) {
      setIsLoading(true);
      sendToBackend(1) // Chama sendToBackend sem await
        .then(() => {
          setCurrentStep(2);

          // Rola para a Etapa 2
          if (etapa2Ref.current) {
            etapa2Ref.current.scrollIntoView({ behavior: 'smooth' });
          }
        })
        .catch((error) => {
          console.error("Erro ao enviar os dados para a etapa 1", error);
        })
        .finally(() => {
          setIsLoading(false); // Defina isLoading como false após o envio dos dados
        });
    } else if (step === 2 && isAllValidEtapa02()) {
      setIsLoading(true);
      sendToBackend(3) // Chama sendToBackend sem await
        .then(() => {
          setCurrentStep(3);

          // Rola para a Etapa 3
          if (etapa3Ref.current) {
            etapa3Ref.current.scrollIntoView({ behavior: 'smooth' });
          }
        })
        .catch((error) => {
          console.error("Erro ao enviar os dados para a etapa 2", error);
        })
        .finally(() => {
          setIsLoading(false); // Defina isLoading como false após o envio dos dados
        });
    }
  };


  // Função para verificar se os dados do formulário são válidos ou se estão vazios a partir do clique do usuário
  const isValidFormData = () => {
    // Verifica se todos os campos obrigatórios estão preenchidos e se não há erros
    const requiredFields = ['nome', 'email', 'email_2', 'cpf', 'whatsapp'];

    for (const fieldName of requiredFields) {
      if (!formData[fieldName] || errors[fieldName]) {
        // Campo em branco ou com erro
        return false;
      }
    }

    return true;
  };
  const isValidFormDataEtapa2 = () => {
    // Verifica se todos os campos obrigatórios da Etapa 2 estão preenchidos e se não há erros
    const requiredFieldsEtapa2 = ['cep', 'endereco', 'bairro', 'numero', 'estado', 'cidade'];

    for (const fieldName of requiredFieldsEtapa2) {
      if (!formData[fieldName] || errors[fieldName]) {
        // Campo em branco ou com erro
        return false;
      }
    }

    return true;
  };
  const isValidFormDataEtapa3 = () => {
    // Verifica se todos os campos obrigatórios da Etapa 3 estão preenchidos e se não há erros
    const requiredFieldsEtapa3 = ['numero_cartao', 'nome_cartao', 'mes_expiracao', 'ano_expiracao', 'codigo_cartao'];

    for (const fieldName of requiredFieldsEtapa3) {
      if (!formData[fieldName] || errors[fieldName]) {
        // Campo em branco ou com erro
        return false;
      }
    }

    return true;
  };

  // ---------- PARTE RELACIONADA AO BACKEND ---------------
  // Estado para rastrear o método de pagamento selecionado.
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('credito'); // default para cartão de crédito

  const sendToBackend = async (etapa) => {
    try {

      const endpointRegister = `/register`;
      const urlRegister = (baseUrl + endpointRegister).replace(/\/+$/, '');
      const response = await axios.post(urlRegister, {
        nome: formData.nome,
        email: formData.email,
        telefone: formData.whatsapp,
        data_nascimento: '',
        identificador: formData.cpf,
        etapa: etapa
      });

      if (response.data.msg === 'Usuário registrado com sucesso!') {
        console.log("Dados enviados");
      } else if (response.data.msg === 'Etapa atualizada com sucesso!') {
        console.log("Etapa Atualizada!");
      }
      else {
        console.log("Erro no envio");
      }
    } catch (error) {
      console.error("Erro ao enviar os dados", error);
    }
  }

  //Rota que guarda o endereço do cliente no banco de dados e faz o processamento já da compra
  const handleConcludePurchase = async () => {

    //Setando o valor total para próxima etapa
    setValor(valorTotalComDesconto);

    // Gera uma sessão para o usuário
    const sessionToken = uuidv4();
    localStorage.setItem('sessionToken', sessionToken);
          
    // Dados que você quer enviar ao gateway
    const paymentData = {
      nome: formData.nome,
      email: formData.email,
      telefone: formData.whatsapp,
      data_nascimento: '0000-00-00',
      identificador: formData.cpf,
      cep: formData.cep,
      endereco: formData.endereco,
      bairro: formData.bairro,
      numero: formData.numero,
      cidade: formData.cidade,
      estado: formData.estado,
      complemento: formData.complemento,
      numero_cartao: formData.numero_cartao,
      nome_cartao: formData.nome_cartao,
      mes_expiracao: formData.mes_expiracao,
      ano_expiracao: formData.ano_expiracao,
      codigo_cartao: formData.codigo_cartao,
      parcelas: numParcelas,
      metodo_pagamento: selectedPaymentMethod,
      quantidade: quantidadeProduto, //Passar aqui a quantidade 
      valor: valorFinal, //Passar aqui o valor total
      cupomId: cupomId || '',
      session: sessionToken
    };

    try {
      setShowPopup(true);

      // Após 20 segundos, troque a mensagem
      setTimeout(() => {
        setShowError(true);
      }, 20000);

      // Dados que você quer enviar ao /address
      const addressData = {
        cep: formData.cep,
        endereco: formData.endereco,
        bairro: formData.bairro,
        numero: formData.numero,
        cidade: formData.cidade,
        estado: formData.estado,
        complemento: formData.complemento,
        pedido_id: '', // Logo adiante o campo sera atualizado
        session: sessionToken, //Obterá a sessão do usuário
      };

      // Enviamos os dados de endereço para /address
      const endpoint2 = `/address`;
      const url2 = (baseUrl + endpoint2).replace(/\/+$/, '');
      await axios.post(url2, addressData);

      // Primeiro, enviamos os dados de pagamento ao gateway
      const endpoint = `/gateway`;
      const url = (baseUrl + endpoint).replace(/\/+$/, '');
      const gatewayResponse = await axios.post(url, paymentData);

      if (gatewayResponse.data.success) {
        // Se tiver sucesso com o gateway, recebe o ID do pedido retornado pela resposta
        const pedidoId = gatewayResponse.data.id_transaction;

        const addressDataUpdate = {
          session: sessionToken,
          pedido_id: pedidoId, // Logo adiante o campo sera atualizado
        };

        // Atualizo a coluna de id_transaction no endereço do cliente
        const endpoint3 = `/address-update`;
        const url2 = (baseUrl + endpoint3).replace(/\/+$/, '');
        const addressUpdate = await axios.post(url2, addressDataUpdate);

        //Caso for pagamento por cartão redireciona para página de Cartão
        if (selectedPaymentMethod === 'credito') {
          // Redirecionar para /cartao com o ID da transação na rota
          navigate(`/cartao/${pedidoId}`);

        } else {
          // Redirecionar para /pix com o ID da transação na rota
          navigate(`/pix/${pedidoId}`);
        }

        if (addressUpdate.data.success) {
          console.log("Compra concluída com sucesso!");

          //Destroi a sessão do usuário
          localStorage.removeItem('sessionToken');
        } else {
          console.error("Erro ao salvar os dados de endereço");
        }
      } else {
        console.error("Erro ao processar a compra no gateway");
      }
    } catch (error) {
      console.error("Erro ao concluir a compra", error);
    }
  };

  async function aplicarCupom() {

    try {
      const endpointCupom = `/cupom`;
      const urlCupom = (baseUrl + endpointCupom).replace(/\/+$/, '');
      const response = await axios.post(urlCupom, { email: 'email@bibliotecacatolica.com.br', cupom, quantidadeProduto });
      //const response = await axios.post(urlCupom, { email: formData.email, cupom, quantidadeProduto });

      if (response.data.message === 'Cupom aplicado com sucesso') {
        // Atualiza o valor total com desconto
        setValorTotalComDesconto(response.data.valorTotalComDesconto);

        // Armazena o cupomId na variável de estado
        setCupomId(response.data.cupomId);

        // Verifica se veio do cupom automático para retornar a mensagem ao cliente
        if (cupomAutomatico === true) {
          // Exibir uma mensagem de sucesso ou atualização no campo de cupom
          setmsgCupom(`Você ganhou ${response.data.valor_porcentagem}% de desconto em sua compra`); // Certifique-se de usar o campo correto do cupom
        } else {
          setmsgCupom("Cupom aplicado com sucesso!");
        }

        // Atualiza a porcentagem de desconto
        setporcentagemDesconto(response.data.valor_porcentagem);

        // Calcula o desconto com a nova porcentagem
        const desconto = (response.data.valor_porcentagem / 100) * 8990;
        const novoValor = 8990 - desconto;

        // Use a função de atualização de estado dentro de uma promise
        await new Promise((resolve) => {
          setvalorFinal(novoValor, () => {
            // O callback será chamado após a atualização completa
            resolve();
          });
        });
      } else {
        // Exibe uma mensagem de erro para o usuário
        setmsgCupom("Cupom inválido ou já foi utilizado");
      }
    } catch (error) {
      //console.error('Erro ao aplicar o cupom', error);
      // Exibe uma mensagem de erro para o usuário
      setmsgCupom("Cupom inválido.");
    }
  }



  // Função para verificar e aplicar automaticamente o cupom com base no e-mail
  const endpointCupom = `/cupom/validar`;
  const urlCupom = (baseUrl + endpointCupom).replace(/\/+$/, '');

  // Função para verificar e aplicar automaticamente o cupom com base no e-mail
  async function verificarCupomPeloEmail() {
    try {
      const response = await axios.get(`${urlCupom}?email=${formData.email}`);
      if (response.status === 200) {
        //Setando o valor true em cupomAutomatico
        setCupomAutomatico(true);

        const cupom = response.data;
        if (cupom.message === 'Cupom válido') {
          // Atualizar o campo de cupom com o valor do cupom encontrado
          setCupom(cupom.cupom);
          //Aplica o cupom de forma automática
          aplicarCupom(cupom.cupom);
        }
      } else {
        // Lidar com erros de resposta do servidor
        console.error('Cupom inválido');
      }
    } catch (error) {
      // Lidar com erros de rede ou outros erros
      //console.error('Erro ao verificar o cupom', error);
    }
  }

  // Crie um array de opções de parcelas
  const parcelaOptions = Array.from({ length: 3 }, (_, index) => {
    const numParcelas = index + 1;
    const valorParcela = calcularValorParcelas(
      quantidadeProduto,
      (porcentagemDesconto / 100) * (quantidadeProduto * valor)
    ) / numParcelas;

    return (
      <MenuItem key={numParcelas} value={numParcelas}>
        {numParcelas}x de {formatCurrency(valorParcela)} (sem juros)
      </MenuItem>
    );
  });

  // Calcule o valor da parcela fora do JSX
  let precoTotal;
  if (selectedPaymentMethod === "credito" && numParcelas > 1) {
    const valorParcela = calcularValorParcelas(
      quantidadeProduto,
      (porcentagemDesconto / 100) * (quantidadeProduto * valor)
    ) / numParcelas;

    precoTotal = (
      <div className='preco_parcelado_resumo'>
        {`${numParcelas}x de ${formatCurrency(valorParcela)}`} <p>( {formatCurrency(valorTotalComDesconto)} )</p>
      </div>
    );
  } else {
    precoTotal = `${formatCurrency(valorTotalComDesconto)}`;
  }


  return (
    <main>
      {/* Popup */}
      {showPopup && (
        <div className="pop_up_loading" id="load_pag">

          {showError ? (
            <>
              <p>
                Ocorreu um erro ao processar seu pedido.
                Tente novamente clicando no link abaixo.
              </p>
              <a href="/" className='botao_tentar_novamente'>Tentar Novamente</a>
              {Sentry.captureException(new Error("Erro ao processar pedido"))}
            </>

          ) : (
            <>
              <div className="loading-spinner" id="loading"></div>
              <p>Processando pagamento...</p>
            </>
          )}
        </div>
      )}
      <div className="contador">
        <div className="container-contador">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="pagamento">
            <img src={selo_seguranca} alt="Selo de Segurança" />
          </div>
        </div>
      </div>

      <form id="checkout-form">

        <div className="view-pc">

          <div className="etapas-check">


            <div className="container">

              <div className="estrutura-check-pc">

                <div className="colunas">
                  <div
                    className="blocos"
                    id="etapa01"
                    style={{ opacity: currentStep === 1 ? 1 : 0.7 }}
                    ref={etapa1Ref}
                  >
                    <div className="bloco-esq" id="primeira_etapa">

                      <div className="cabecalho">
                        <div className="etapa-check">
                          <div className="numero">1</div>
                          <h2>DADOS</h2>
                        </div>
                      </div>

                      <div className="corpo-form" id="corpo-form01">
                        <div className="estrutura-pc-inputs">

                          {/* Nome */}
                          <div className="group-input-pc">
                            <TextField
                              label="Nome completo"
                              variant="outlined"
                              type="text"
                              name="nome"
                              id="input1"
                              placeholder="Nome"
                              required
                              value={formData.nome}
                              onChange={handleInputChange}
                              error={!!errors.nome}
                              helperText={errors.nome || ''}
                            />
                          </div>

                          {/* Email */}
                          <div className="group-input-pc">
                            <TextField
                              label="E-mail"
                              variant="outlined"
                              type="text"
                              name="email"
                              id="input2"
                              placeholder="E-mail"
                              required
                              value={formData.email}
                              onChange={handleInputChange}
                              error={!!errors.email}
                              helperText={errors.email || ''}
                            />
                          </div>

                          {/* Confirmar Email */}
                          <div className="group-input-pc">
                            <TextField
                              label="Confirmação de E-mail"
                              variant="outlined"
                              type="text"
                              name="email_2"
                              id="input3"
                              placeholder="Digite novamente seu e-mail"
                              required
                              value={formData.email_2}
                              onChange={handleInputChange}
                              error={!!errors.email_2}
                              helperText={errors.email_2 || ''}
                            />
                          </div>

                          {/* CPF */}
                          <div className="group-input-pc">
                            <InputMask
                              mask="999.999.999-99"
                              maskChar=""
                              value={formData.cpf}
                              onChange={handleInputChange}
                            >
                              {() => (
                                <TextField
                                  label="CPF"
                                  variant="outlined"
                                  type="text"
                                  fullWidth
                                  error={!!errors.cpf}
                                  helperText={errors.cpf || ''}
                                  name="cpf"
                                  id="input4"
                                  placeholder="000.000.000-00"
                                  required
                                />
                              )}
                            </InputMask>
                          </div>

                          {/* Celular / Whatsapp */}
                          <div className="group-input-pc">
                            <InputMask
                              mask="(99) 99999-9999"
                              maskChar=""
                              value={formData.whatsapp}
                              onChange={handleInputChange}
                            >
                              {() => (
                                <TextField
                                  label="Whatsapp"
                                  variant="outlined"
                                  type="text"
                                  error={!!errors.whatsapp}
                                  helperText={errors.whatsapp || ''}
                                  name="whatsapp"
                                  id="input5"
                                  placeholder="Telefone"
                                  required
                                />
                              )}
                            </InputMask>
                          </div>
                          <p>Ao clicar em <b>começar</b>, confirmo que li e concordo com os <Link to="https://site.livrosdambc.com.br/server_checkout/termos.pdf" target='_blank'>Termos e Condições</Link> e a <Link to="https://clube.bibliotecacatolica.com.br/politica-de-privacidade" target='_blank'>Política de Privacidade.</Link></p>
                        </div>

                        {/** Adicionar a ação de enviar esses dados inicias como LEAD tanto para o banco quanto ao ACTIVECAMPAING **/}
                        {currentStep === 1 && (
                          <div className="botao-form" id="avanca_etapa01" onClick={() => {
                            if (isValidFormData()) {
                              handleNextStep(1);
                            } else {
                              // Define uma mensagem de erro e marca os campos com erros
                              setErrors({
                                ...errors,
                                nome: !formData.nome ? 'Campo obrigatório' : errors.nome,
                                email: !formData.email ? 'Campo obrigatório' : errors.email,
                                email_2: !formData.email_2 ? 'Campo obrigatório' : errors.email_2,
                                cpf: !formData.cpf ? 'Campo obrigatório' : errors.cpf,
                                whatsapp: !formData.whatsapp ? 'Campo obrigatório' : errors.whatsapp,
                              });
                            }
                          }} disabled={isLoading}>
                            {isLoading ? "Carregando..." : "Começar"}
                          </div>
                        )}

                        {currentStep > 1 && (
                          <div
                            className="botao-form-alterar"
                            id="edita_etapa01"
                            onClick={() => {
                              setCurrentStep(1);
                              // Rola suavemente de volta para a Etapa 1
                              if (etapa1Ref.current) {
                                etapa1Ref.current.scrollIntoView({ behavior: 'smooth' });
                              }
                            }}
                            style={{ pointerEvents: 'auto' }}
                          >
                            <img src={alterar} alt="Icone Alterar" /> Alterar
                          </div>
                        )}
                      </div>

                    </div>
                  </div>

                </div>

                <div className="colunas">
                  <div
                    className="blocos"
                    id="etapa02"
                    style={{
                      opacity: currentStep === 2 ? 1 : 0.7,
                      pointerEvents: currentStep === 2 ? 'auto' : 'none'
                    }}
                    ref={etapa2Ref}
                  >

                    <div className="bloco-esq" id="segunda_etapa">
                      <div className="cabecalho">
                        <div className="etapa-check">
                          <div className="numero">2</div>
                          <h2>ENTREGA E PAGAMENTO</h2>
                        </div>

                        <div className="corpo-form" id="corpo-form02">
                          <div className="estrutura-pc-inputs">

                            {/* CEP */}
                            <div className="group-input-pc">
                              <TextField
                                inputProps={{
                                  maxLength: 9,
                                }}
                                label="CEP"
                                variant="outlined"
                                type="text"
                                name="cep"
                                id="inputCEP"
                                maxLength="9"
                                value={formData.cep || ""}
                                placeholder="00000-000"
                                required
                                onChange={handleInputChange}
                                error={!!errors.cep}
                                helperText={errors.cep || ''}
                              />
                              {/* Elemento para exibir o status "Buscando cep..." */}
                              {cepStatus && <div className="cepStatus">{cepStatus}</div>}
                            </div>

                            {/* Endereço */}
                            <div className="group-input-pc">
                              <TextField
                                label="Endereço"
                                variant="outlined"
                                type="text"
                                name="endereco"
                                id="input7"
                                placeholder="Endereço"
                                value={formData.endereco}
                                readOnly={!isAddressEditable}
                                required
                                onChange={handleInputChange}
                                error={!!errors.endereco}
                                helperText={errors.endereco || ''}
                                className={isAddressEditable ? "auto-filled" : ""}
                                InputLabelProps={{
                                  shrink: !isAddressEditable || !!formData.endereco
                                }}
                              />
                            </div>

                            {/* Bairro */}
                            <div className="group-input-pc">
                              <TextField
                                label="Bairro"
                                variant="outlined"
                                type="text"
                                name="bairro"
                                id="input8"
                                placeholder="Bairro"
                                value={formData.bairro}
                                readOnly={!isAddressEditable}
                                required
                                onChange={handleInputChange}
                                error={!!errors.bairro}
                                helperText={errors.bairro || ''}
                                className={isAddressEditable ? "auto-filled" : ""}
                                InputLabelProps={{
                                  shrink: !isAddressEditable || !!formData.bairro
                                }}
                              />
                            </div>
                          </div>

                          <div className="estrutura-pc-inputs">
                            {/* Número */}
                            <div className="group-input-pc">
                              <TextField
                                label="Número"
                                variant="outlined"
                                type="text"
                                name="numero"
                                id="input9"
                                placeholder="000"
                                value={formData.numero}
                                readOnly={!isAddressEditable}
                                required
                                onChange={handleInputChange}
                                error={!!errors.numero}
                                helperText={errors.numero || ''}
                                className={isAddressEditable ? "auto-filled" : ""}
                                InputLabelProps={{
                                  shrink: !isAddressEditable || !!formData.numero
                                }}
                              />
                            </div>
                            {/* Estado */}
                            <div className="group-input-pc coluna2">
                              {/* Cidade */}
                              <TextField
                                label="Cidade"
                                variant="outlined"
                                type="text"
                                name="cidade"
                                id="input11"
                                placeholder="Cidade"
                                value={formData.cidade}
                                readOnly={!isAddressEditable}
                                required
                                onChange={handleInputChange}
                                error={!!errors.cidade}
                                helperText={errors.cidade || ''}
                                className={isAddressEditable ? "auto-filled" : ""}
                                InputLabelProps={{
                                  shrink: !isAddressEditable || !!formData.cidade
                                }}
                              />
                              {/* Estado */}
                              <TextField
                                label="Estado"
                                variant="outlined"
                                type="text"
                                name="estado"
                                id="input10"
                                placeholder="Estado"
                                value={formData.estado}
                                readOnly={!isAddressEditable}
                                required
                                onChange={handleInputChange}
                                error={!!errors.estado}
                                helperText={errors.estado || ''}
                                className={isAddressEditable ? "auto-filled" : ""}
                                InputLabelProps={{
                                  shrink: !isAddressEditable || !!formData.estado
                                }}
                              />

                            </div>
                          </div>

                          <div className="estrutura-pc-inputs">
                            {/* Complemento */}
                            <div className="group-input-pc">
                              <TextField
                                label="Complemento"
                                variant="outlined"
                                type="text"
                                name="complemento"
                                id="input12"
                                placeholder="Complemento (Opcional)"
                                maxLength="25"
                                onChange={handleInputChange}
                                error={!!errors.complemento}
                                helperText={errors.complemento || ''}
                              />
                            </div>
                          </div>

                          <div className="linha_resumo"></div>
                          <div className="estrutura-pc-inputs forma_pagamento_title">
                            <p>Selecione a forma de pagamento</p>
                          </div>

                          <div className="option-pag">

                            <div className="acordion" id="cartao">
                              <div className="itens_pagamento" id="btn_pagamentos">
                                {/*Manipulando o evento de clique para atualizar o estado de pagamento. */}
                                <div
                                  className={`btn_opcao ${selectedPaymentMethod === 'credito' ? 'btn_opcao_ativo' : ''}`}
                                  id="btn_cartao"
                                  onClick={() => setSelectedPaymentMethod('credito')}
                                >
                                  <input type="radio" name="opcao_pagamento" value="credito" id="radio_pag_1" checked={selectedPaymentMethod === 'credito'} />
                                  <img src={cartao} alt="Cartão" />
                                  <p>Cartão de Crédito</p>
                                </div>
                                <div
                                  className={`btn_opcao ${selectedPaymentMethod === 'pix' ? 'btn_opcao_ativo' : ''}`}
                                  id="btn_pix"
                                  onClick={() => setSelectedPaymentMethod('pix')}
                                >
                                  <input type="radio" name="opcao_pagamento" value="pix" id="radio_pag_4" checked={selectedPaymentMethod === 'pix'} />
                                  <img src={pix} alt="Pix" />
                                  <p>Pix</p>
                                </div>
                              </div>

                              <div className="linha_resumo"></div>

                              <div className="estrutura-pc-inputs">
                                <div className='quantidade_produto'>
                                  <div className="estrutura_inputs_qtd">
                                    <div className="group_qtd">
                                      <label htmlFor="input20">Quantidade</label>
                                      <div className='contador_quantidade'>
                                        <div className='btn_quantidade' onClick={decrementQuantidadeProduto}>-</div>
                                        <span>{quantidadeProduto}</span>
                                        <div className='btn_quantidade' onClick={incrementQuantidadeProduto}>+</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <input type="hidden" name="forma_pag" value="1" id="forma_pagamento" />

                              {selectedPaymentMethod === 'credito' && (
                                <div className="conteudo-cartao" id="conteudo_cartao">
                                  <div className="linha_resumo"></div>

                                  {/* Seção do cartão de crédito */}
                                  <div className="estrutura-pc-inputs">
                                    <div className="group-input-pc">
                                      <InputMask
                                        mask="9999 9999 9999 9999"
                                        maskChar=""
                                        value={formData.numero_cartao}
                                        onChange={handleInputChange}
                                      >
                                        {() => (
                                          <TextField
                                            label="Número do cartão"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            error={!!errors.numero_cartao}
                                            helperText={errors.numero_cartao || ''}
                                            name="numero_cartao"
                                            id="input13"
                                            placeholder="Número do cartão"
                                            required
                                          />
                                        )}
                                      </InputMask>
                                    </div>

                                    <div className="group-input-pc">
                                      <TextField
                                        label="Nome impresso no cartão"
                                        variant="outlined"
                                        maxLength="50"
                                        name="nome_cartao"
                                        type="text"
                                        onChange={handleInputChange}
                                        value={formData.nome_cartao}
                                        id="input14"
                                        required
                                        placeholder="Número do cartão"
                                        error={!!errors.nome_cartao}
                                        helperText={errors.nome_cartao || ''}
                                      />
                                    </div>
                                  </div>

                                  <div className="estrutura-pc-inputs">
                                    <div className="coluna2">
                                      <div className="group-input-pc">
                                        <InputMask
                                          mask="99"
                                          maskChar=""
                                          value={formData.mes_expiracao}
                                          onChange={handleInputChange}
                                        >
                                          {() => (
                                            <TextField
                                              label="Mês"
                                              variant="outlined"
                                              type="text"
                                              fullWidth
                                              error={!!errors.mes_expiracao}
                                              helperText={errors.mes_expiracao || ''}
                                              name="mes_expiracao"
                                              id="input15"
                                              placeholder="00"
                                              required
                                            />
                                          )}
                                        </InputMask>
                                      </div>
                                      <div className="group-input-pc">
                                        <InputMask
                                          mask="99"
                                          maskChar=""
                                          value={formData.ano_expiracao}
                                          onChange={handleInputChange}
                                        >
                                          {() => (
                                            <TextField
                                              label="Ano"
                                              variant="outlined"
                                              type="text"
                                              fullWidth
                                              error={!!errors.ano_expiracao}
                                              helperText={errors.ano_expiracao || ''}
                                              name="ano_expiracao"
                                              id="input16"
                                              placeholder="00"
                                              required
                                            />
                                          )}
                                        </InputMask>
                                      </div>
                                      <div className="group-input-pc">
                                        <InputMask
                                          mask="9999"
                                          maskChar=""
                                          value={formData.codigo_cartao}
                                          onChange={handleInputChange}
                                        >
                                          {() => (
                                            <TextField
                                              label="Código CVC"
                                              variant="outlined"
                                              type="text"
                                              fullWidth
                                              error={!!errors.codigo_cartao}
                                              helperText={errors.codigo_cartao || ''}
                                              name="codigo_cartao"
                                              id="input17"
                                              placeholder="CVC"
                                              required
                                            />
                                          )}
                                        </InputMask>
                                      </div>
                                    </div>
                                  </div>

                                  <input type="hidden" name="id_produto" value="1918" />

                                  {/* Parcelas */}
                                  <div className="estrutura-pc-inputs">
                                    <div className="group-input-pc">
                                      <TextField
                                        label="Parcelas"
                                        variant="outlined"
                                        id="parcelas"
                                        select
                                        value={numParcelas}
                                        onChange={(e) => setNumParcelas(parseInt(e.target.value))}
                                        fullWidth
                                      >
                                        {parcelaOptions}
                                      </TextField>
                                    </div>
                                  </div>

                                </div>
                              )}
                              <div className="estrutura-pc-inputs parte_cupom">
                                <div className="linha_resumo"></div>
                                <div className="group-input-pc coluna2">
                                  <TextField
                                    label="Cupom de desconto"
                                    variant="outlined"
                                    maxLength="50"
                                    value={cupom}
                                    onChange={(e) => setCupom(e.target.value)}
                                    type="text"
                                    placeholder="Cupom de desconto"
                                  />
                                  {/* Botão para aplicar o cupom */}
                                  <div
                                    className="botao_cupom"
                                    onClick={aplicarCupom}
                                  >Aplicar</div>
                                </div>
                                {msgCupom && <div className="cupom">{msgCupom}</div>}
                              </div>

                              {selectedPaymentMethod === 'boleto' && (
                                <div className="conteudo-boleto" id="conteudo_boleto">
                                  <p>Clique em "Concluir Compra" e você poderá ver o BOLETO na próxima etapa</p>
                                </div>
                              )}
                              {selectedPaymentMethod === 'pix' && (
                                <div className="conteudo-boleto" id="conteudo_pix">
                                  <div className="linha_resumo"></div>
                                  <p>Ao clicar em "Concluir Compra" na próxima etapa você poderá ver o PIX</p>
                                </div>
                              )}

                            </div>

                          </div>

                          <p id="avanca_etapa02_Error" className="alert_error">Favor preencher todos os campos corretamente.</p>

                          {/** Adicionar a ação de enviar esses dados de Endereço do LEAD e a ETAPA02 ao ACTIVECAMPAING **/}
                          {currentStep === 2 && (
                            <div className="botao-form" id="avanca_etapa02" onClick={() => {
                              //Valida os campos do cartão do cliente
                              if (selectedPaymentMethod === "credito") {
                                if (isValidFormDataEtapa2() && isValidFormDataEtapa3()) {
                                  handleNextStep(2);
                                } else {
                                  // Define uma mensagem de erro e marca os campos com erros
                                  setErrors({
                                    ...errors,
                                    cep: !formData.cep ? 'Campo obrigatório' : errors.cep,
                                    endereco: !formData.endereco ? 'Campo obrigatório' : errors.endereco,
                                    bairro: !formData.bairro ? 'Campo obrigatório' : errors.bairro,
                                    numero: !formData.numero ? 'Campo obrigatório' : errors.numero,
                                    estado: !formData.estado ? 'Campo obrigatório' : errors.estado,
                                    cidade: !formData.cidade ? 'Campo obrigatório' : errors.cidade,
                                    numero_cartao: !formData.numero_cartao ? 'Campo obrigatório' : errors.numero_cartao,
                                    nome_cartao: !formData.nome_cartao ? 'Campo obrigatório' : errors.nome_cartao,
                                    mes_expiracao: !formData.mes_expiracao ? 'Campo obrigatório' : errors.mes_expiracao,
                                    ano_expiracao: !formData.ano_expiracao ? 'Campo obrigatório' : errors.ano_expiracao,
                                    codigo_cartao: !formData.codigo_cartao ? 'Campo obrigatório' : errors.codigo_cartao,
                                  });
                                }
                              } else {
                                if (isValidFormDataEtapa2()) {
                                  handleNextStep(2);
                                } else {
                                  // Define uma mensagem de erro e marca os campos com erros
                                  setErrors({
                                    ...errors,
                                    cep: !formData.cep ? 'Campo obrigatório' : errors.cep,
                                    endereco: !formData.endereco ? 'Campo obrigatório' : errors.endereco,
                                    bairro: !formData.bairro ? 'Campo obrigatório' : errors.bairro,
                                    numero: !formData.numero ? 'Campo obrigatório' : errors.numero,
                                    estado: !formData.estado ? 'Campo obrigatório' : errors.estado,
                                    cidade: !formData.cidade ? 'Campo obrigatório' : errors.cidade,
                                  });
                                }
                              }
                            }
                            } disabled={isLoading}>
                              {isLoading ? "Carregando..." : "Avançar"}
                            </div>
                          )}
                          {currentStep > 2 && (
                            <div
                              className="botao-form-alterar"
                              id="edita_etapa02"
                              onClick={() => {
                                setCurrentStep(2);
                                // Rola suavemente de volta para a Etapa 1
                                if (etapa2Ref.current) {
                                  etapa2Ref.current.scrollIntoView({ behavior: 'smooth' });
                                }
                              }}
                              style={{ pointerEvents: 'auto' }}
                            >
                              <img src={alterar} alt="Icone Alterar" /> Alterar
                            </div>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="colunas">

                  <div
                    className="blocos"
                    id="etapa03"
                    style={{
                      opacity: currentStep === 3 ? 1 : 0.7,
                      pointerEvents: currentStep === 3 ? 'auto' : 'none'
                    }}
                    ref={etapa3Ref}
                  >
                    <div className="bloco-esq" id="terceira_etapa">

                      <div className="cabecalho">
                        <div className="etapa-check">
                          <div className="numero">3</div>
                          <h2>CONFIRMAÇÃO</h2>
                        </div>
                      </div>

                      <div className="corpo-form" id="corpo-form03">
                        <div className="tab-resumo">

                          <div className="detalhes-finais">
                            <div className="imagem_produto">
                              <img src={produto} alt="Produto" />
                            </div>

                            <div className="linha_resumo"></div>
                            <div className="content-resumo">
                              <div className="titulo-resumo">
                                <img src={certo} alt="Icone contém" /> Quantidade</div>
                              <p>{quantidadeProduto}</p>
                            </div>
                            <div className="content-resumo">
                              <div className="titulo-resumo">
                                <img src={certo} alt="Icone contém" /> Frete</div>
                              <p>Grátis</p>
                            </div>

                            <div className="linha_resumo"></div>

                            <div className="content-resumo">
                              <div className="titulo-resumo">Valor unitário</div>
                              <p>{formatCurrency(valor)}
                              </p>
                            </div>

                            {selectedPaymentMethod === "credito" && (
                              <div className="content-resumo">
                                <div className="titulo-resumo">Nº de Parcelas</div>
                                <p>{numParcelas}</p>
                              </div>
                            )}

                            <div className="linha_resumo"></div>
                            <div className="content-resumo">
                              <div className="titulo-total">Total</div>
                              <p className="titulo-total-preco">{precoTotal}</p>
                            </div>
                          </div>

                          {/** Esse botão processará a compra na PAGARME e enviará os dados ao BANCO DE DADOS, nessa etapa não tem o Active no meio**/}
                          {/* Atualize o valor total com base na quantidade de produtos */}
                          {currentStep === 3 && (
                            <div className="botao-form" id="botao_final" onClick={() => {
                              handleConcludePurchase(); // Chama a função para processar a compra
                              setShowPopup(true); // Define showPopup como true para mostrar o popup
                            }} disabled={isLoading}>
                              {isLoading ? "Finalizando..." : "Concluir Compra"}
                            </div>
                          )}


                          <p id="final_Error" className="alert_error">Favor preencher todos os campos corretamente.</p>

                        </div>

                      </div>

                    </div>

                  </div>

                </div> {/**Fim da Coluna3**/}

              </div>

            </div>


          </div>

        </div>

      </form>

    </main >

  );
}

export default Checkout;
