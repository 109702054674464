import React from 'react';
import ReactDOM from 'react-dom/client'; // Importa o cliente ReactDOM
import './style.css';
import './style_mobile.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";

// Inicialização do Sentry
Sentry.init({
  dsn: "https://98e888056aa9c19216a3d9958011f4d0@o4504640577339392.ingest.sentry.io/4506673312497664",
  integrations: [
    new Sentry.BrowserTracing({
      // Controla para quais URLs a propagação de rastreamento distribuído deve ser habilitada
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Monitoramento de Desempenho
  tracesSampleRate: 1.0, // Captura 100% das transações
  // Replay de Sessão
  replaysSessionSampleRate: 0.1, // Define a taxa de amostra em 10%. Você pode querer alterar para 100% em desenvolvimento e depois amostrar em uma taxa menor em produção.
  replaysOnErrorSampleRate: 1.0, // Se você não está amostrando a sessão inteira, mude a taxa de amostra para 100% ao amostrar sessões onde ocorrem erros.
});

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Se desejar começar a medir a performance do seu app, passe uma função
// para registrar os resultados (por exemplo: reportWebVitals(console.log))
// ou envie para um endpoint de analytics. Saiba mais: https://bit.ly/CRA-vitals
reportWebVitals();
